import { navigate } from 'gatsby';
import React, { useEffect, useRef } from 'react';
import { withOptimizely, WithOptimizelyProps } from '@optimizely/react-sdk';
import { RouteComponentProps } from '@reach/router';
import { StatusType } from '@datadog/browser-logs';
import { Mixpanel } from 'mixpanel-browser';
import { useAtomValue } from 'jotai';

import { NewBoilerDetails } from './NewBoilerDetails';

import { ROUTE_CONFIRMATION, ROUTE_HOME, ROUTE_HOME_RECOVER_CONFIRMATION } from '@constants/routes';
import { MixpanelEvents } from '@entities/enums';
import * as Logger from '@src/logging/logger';
import { journeyAtom, selectedProductAtom, signupCompleteAtom } from '@src/store/store';
import { Journey } from '@src/types/Journey';

interface Props extends RouteComponentProps, WithOptimizelyProps {
  mixpanel: Mixpanel;
}

const NewBoilerDetailsPageComponent: React.FC<Props> = ({ mixpanel, optimizely, location }) => {
  const journey = useAtomValue(journeyAtom);
  const selectedProduct = useAtomValue(selectedProductAtom);

  const signupComplete = useAtomValue(signupCompleteAtom);

  const mixpanelRef = useRef(mixpanel);
  const optimizelyRef = useRef(optimizely);

  useEffect(() => {
    Logger.log('Product selected', { coverType: selectedProduct }, StatusType.info);
  }, [selectedProduct]);

  useEffect(() => {
    if (!selectedProduct) {
      navigate(ROUTE_HOME);
      return;
    }

    if (signupComplete) {
      navigate(
        journey === Journey.HomeRecover ? ROUTE_HOME_RECOVER_CONFIRMATION : ROUTE_CONFIRMATION
      );
      return;
    }
  }, [journey, selectedProduct, signupComplete]);

  useEffect(() => {
    mixpanelRef.current.track(MixpanelEvents.PAGE_BOILER, {
      pageName: null,
    });
    optimizelyRef.current?.track(MixpanelEvents.PAGE_BOILER);
  }, []);

  return <NewBoilerDetails location={location} />;
};

export const NewBoilerDetailsPage = withOptimizely<Props, null>(NewBoilerDetailsPageComponent);
