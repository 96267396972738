import { Form, Formik, FormikErrors, FormikProps } from 'formik';
import { navigate } from 'gatsby';
import { useState } from 'react';
import styled from 'styled-components';
import {
  Card,
  Checkbox,
  Divider,
  Heading3,
  InfoNotification,
  Li,
  Margin,
  P,
  Radio,
  RadioField,
  SelectField,
  Strong,
  TextLink,
  Ul,
  WarningNotification,
} from '@ovotech/element';
import { ErrorObject } from 'ajv';
import { omitBy } from 'lodash';
import { useDecision } from '@optimizely/react-sdk';
import { useAtom, useAtomValue } from 'jotai';

import { getBoilerAgeOptionsBasedOnMake } from './utils';
import { minBoilerAge } from './constant';

import { BasicPage } from '@components';
import { InfoContainer, InfoText } from '@components/Info';
import { TrackFormErrors } from '@components/TrackFormErrors';
import {
  ROUTE_DETAILS,
  ROUTE_HOME_RECOVER_DETAILS,
  ROUTE_HOME_RECOVER_PAYMENT,
  ROUTE_PAYMENT,
} from '@constants/routes';
import { BoilerBrand, BoilerBrandExclusions, ErrorMessages, MixpanelEvents } from '@entities/enums';
import { OptimizelyFeatureFlag } from '@entities/optimizely';
import { boilerBrands } from '@fixtures';
import { useCustomMixpanel } from '@services/Mixpanel';
import {
  BoilerDetails,
  boilerDetailsAtom,
  journeyAtom,
  selectedProductAtom,
  trackingAtom,
} from '@src/store/store';
import { getBoilerAgeNumber } from '@utils/getBoilerAgeNumber';
import { getReferrerUrlFor } from '@utils/getReferrerUrl';
import { useMediaIs } from '@hooks/useMediaIs';
import { mqMediumUp } from '@utils/mediaQuery';
import { MarginDivider } from '@src/Styles/Common.Styles';
import { formatBoilerDetailsErrors } from '@utils/signupValidationHelper';
import { useSignupValidation } from '@hooks/useSignupValidation';
import { createAppliance, createCoveredProperty } from '@src/factories/Signup/SignupFactory';
import { scrollToError } from '@utils/scrollToError';
import { Journey } from '@src/types/Journey';
import { useQuotesPlatform } from '@hooks/useQuotesPlatform';
import { ProductCode } from '@src/types/Quote';
import { usePromoComponentsNew } from '@hooks/usePromoComponentsNew';
import { BasketV2 } from '@components/BasketV2/BasketV2';
import { BasketNavigationButtonsV2 } from '@components/BasketV2/BasketNavigationsButtons';

const PageContentContainer = styled.div`
  display: flex;
`;

const StyledCard = styled(Card)`
  ${({ theme: { core } }) =>
    `padding: ${core.space[4]}; 
    ${mqMediumUp(`padding: ${core.space[6]};`)};
   `}
`;

const StyledWarningNotification = styled(WarningNotification)`
  ${({ theme: { semantic } }) => `
      & > div {
        border: 1px solid ${semantic.info.surface};
       }
  `}
`;

export const StyledInfoNotification = styled(InfoNotification)`
  ${({ theme: { semantic } }) => `
      & > div {
        border: 1px solid ${semantic.info.surface};
       }
  `}
`;

const FieldDescription = styled(P)`
  margin-top: ${(props) => props.theme.core.space[1]};
`;

const boilerBrandDropdownOptions = boilerBrands.map((brand) => {
  return { value: brand.id, label: brand.name };
});

const boilerTooOldMessage =
  "The age of your boiler might mean we're not currently able to offer you this plan. Please call 0330 102 8905 and one of the team will confirm.";

const boilerModelsExemptions = [
  {
    name: BoilerBrandExclusions.EXCLUDED,
    label: 'Yes',
  },
  {
    name: BoilerBrandExclusions.NOTEXCLUDED,
    label: 'No',
  },
];

const warningBoilerBrands = [BoilerBrand.IDEAL, BoilerBrand.BAXI_POTTERTON, BoilerBrand.GLOWWORM];

const isExcludedBoiler = (selectedBoiler: BoilerBrand | '') => {
  return warningBoilerBrands.some((boilerBrand) => boilerBrand == selectedBoiler);
};

const cannotOfferError = 'Unfortunately, we can’t currently offer you this plan.';

const emptySelection = {
  value: '',
  label: '',
};

const boilerAgeSelection = {
  value: '0',
  label: 'less than 1',
};

export const NewBoilerDetailsInputs = () => {
  const [{ enabled: useNewSignupAPI }] = useDecision(OptimizelyFeatureFlag.NEW_SIGNUP_API);
  const { validateAppliance, validateCoveredProperty } = useSignupValidation();
  const selectedProduct = useAtomValue(selectedProductAtom);
  const [boilerDetails, setBoilerDetails] = useAtom(boilerDetailsAtom);
  const journey = useAtomValue(journeyAtom);
  const tracking = useAtomValue(trackingAtom);
  const { getOlderBoilerPremiumNotification } = usePromoComponentsNew();
  const isMobile = useMediaIs('mobile', 'max');
  const { track } = useCustomMixpanel();
  const [excludedModelSelected, setExcludedModelSelected] = useState(
    BoilerBrandExclusions.NOTEXCLUDED
  );

  const isHomeRecover = journey === Journey.HomeRecover;

  const defaultBoilerAge = { boilerAge: minBoilerAge.toString() };

  const { getQuoteByProductCode } = useQuotesPlatform();

  const selectedQuote = selectedProduct && getQuoteByProductCode(selectedProduct);

  const isAbs = selectedQuote?.product === ProductCode.ABS;

  const backButtonText = isAbs ? 'Back to Plan' : 'Back to personal details';

  const nextText = 'Go to payment details';

  const validate = (values: BoilerDetails): FormikErrors<BoilerDetails> => {
    const chosenBrand = boilerBrands.find((b) => b.id === values.make);
    const isNotSupported = values.isNotSupportedBoiler === BoilerBrandExclusions.EXCLUDED;
    const isTooOld =
      values.make !== BoilerBrand.OTHER &&
      chosenBrand &&
      getBoilerAgeNumber(values.boilerAge) >= chosenBrand.maxAge;
    const isOtherBrand = values.make === BoilerBrand.OTHER;

    if (useNewSignupAPI) {
      const cleanValues = omitBy(values, (value) => typeof value === 'string' && value === '');
      const appliance = createAppliance(cleanValues);
      const coveredProperty = createCoveredProperty(cleanValues);

      const applianceResult = validateAppliance(appliance);
      const coveredPropertyResult = validateCoveredProperty(coveredProperty);

      let errorsList: ErrorObject[] = [];

      if (applianceResult.errors) {
        errorsList = [...errorsList, ...applianceResult.errors];
      }

      if (coveredPropertyResult.errors) {
        errorsList = [...errorsList, ...coveredPropertyResult.errors];
      }

      const errors = formatBoilerDetailsErrors(errorsList);

      if (isTooOld) {
        errors.boilerAge = boilerTooOldMessage;
      }

      if (isNotSupported) {
        errors.isNotSupportedBoiler = cannotOfferError;
      }

      if (isOtherBrand) {
        errors.make = 'We are sorry, only the boiler brands listed are covered.';
      }

      return errors;
    }

    const errors: FormikErrors<BoilerDetails> = {};

    if (!values.make) {
      errors.make = ErrorMessages.BOILER_BRAND_REQUIRED;
    } else if (isOtherBrand) {
      errors.make = 'We are sorry, only the boiler brands listed are covered.';
    }
    if (!values.boilerAge) {
      errors.boilerAge = ErrorMessages.BOILER_AGE_REQUIRED;
    }
    if (!values.ownership) {
      errors.ownership = ErrorMessages.OWNERSHIP_REQUIRED;
    }
    if (!values.notGuesthouse) {
      errors.notGuesthouse = ErrorMessages.NOT_GUEST_HOUSE_REQUIRED;
    }

    if (isTooOld) {
      errors.boilerAge = boilerTooOldMessage;
    }

    if (isNotSupported) {
      errors.isNotSupportedBoiler = cannotOfferError;
    }

    if (errors.boilerAge || errors.make || errors.notGuesthouse || errors.ownership) {
      scrollToError(errors);
    }

    return errors;
  };

  const handleBackButtonClick = () => {
    const referrerUrl = getReferrerUrlFor(tracking.source);

    if (referrerUrl) {
      window.location.href = referrerUrl;
    } else {
      navigate(
        isHomeRecover
          ? `${ROUTE_HOME_RECOVER_DETAILS}${window.location.search}`
          : `${ROUTE_DETAILS}${window.location.search}`
      );
    }
  };

  const onSubmit = (values: BoilerDetails): void => {
    const boilerAge = isAbs ? minBoilerAge.toString() : values.boilerAge;
    setBoilerDetails({ ...values, boilerAge });

    if (isHomeRecover) {
      navigate(`${ROUTE_HOME_RECOVER_PAYMENT}${window.location.search}`);
      return;
    }
    navigate(`${ROUTE_PAYMENT}${window.location.search}`);
  };

  return (
    <Formik
      initialValues={{ ...boilerDetails, ...(isAbs && defaultBoilerAge) }}
      onSubmit={onSubmit}
      validate={validate}
    >
      {({
        handleChange,
        isSubmitting,
        values,
        errors,
        submitCount,
      }: FormikProps<BoilerDetails>) => {
        const hasTriedToSubmit = submitCount > 0;
        const errorsToTrack = { ...errors };
        delete errorsToTrack['isNotSupportedBoiler'];

        return (
          <div>
            <Form data-testid="boilerForm" role="form">
              <TrackFormErrors errors={errorsToTrack} trackErrors={hasTriedToSubmit} />
              <PageContentContainer>
                <BasicPage.Content>
                  <BasicPage.Left>
                    <StyledCard>
                      <>
                        <Heading3>What brand of boiler do you have?</Heading3>
                        <Margin vertical={2} />
                        {isAbs ? (
                          <FieldDescription>
                            If your brand isn’t in the drop-down then we can’t currently offer you
                            this plan.{' '}
                            <span style={{ textDecoration: 'underline' }}>
                              Not sure about the brand?
                            </span>{' '}
                            It’ll either be on the boiler or in the instruction manual.
                          </FieldDescription>
                        ) : (
                          <FieldDescription>
                            Select from the drop-down below. If your brand isn’t in the drop-down
                            then we can’t currently offer you boiler cover. Not sure about the
                            brand? It’ll either be on the boiler or in the instruction manual.
                          </FieldDescription>
                        )}
                        <Margin vertical={4} />
                        <SelectField
                          data-testid="make"
                          id="make"
                          name="make"
                          label="Select boiler brand"
                          options={[emptySelection, ...boilerBrandDropdownOptions]}
                          value={values.make}
                          onChange={handleChange}
                          error={errors.make}
                        />
                      </>
                      {isExcludedBoiler(values.make) ? (
                        <Margin top={6}>
                          <StyledWarningNotification
                            id="cover-info"
                            nonce={undefined}
                            title={'Warning'}
                          >
                            <InfoContainer>
                              <InfoText>
                                <>
                                  <Strong>
                                    <Margin bottom={1}>
                                      At the moment we can&apos;t cover these specific models for
                                      your brand of boiler:
                                    </Margin>
                                    <Ul>
                                      {values.make == BoilerBrand.IDEAL ? <Li>Istor</Li> : null}
                                      {values.make == BoilerBrand.BAXI_POTTERTON ? (
                                        <>
                                          <Li>Bermuda back boiler</Li>
                                          <Li>Eco Gen dual purpose</Li>
                                          <Li>Kingfisher</Li>
                                        </>
                                      ) : null}
                                      {values.make == BoilerBrand.GLOWWORM ? (
                                        <Li>HomeGlow BBU back boiler</Li>
                                      ) : null}
                                    </Ul>
                                  </Strong>
                                  <Margin top={3}>
                                    <RadioField
                                      name="isNotSupportedBoiler"
                                      label={
                                        <Margin bottom={1}>
                                          Is your boiler one of these models?
                                        </Margin>
                                      }
                                      onChange={(e) => {
                                        handleChange(e);
                                        const { value } = e.target as HTMLInputElement;
                                        if (value === BoilerBrandExclusions.EXCLUDED) {
                                          setExcludedModelSelected(BoilerBrandExclusions.EXCLUDED);
                                          track(MixpanelEvents.ERROR, {
                                            field: 'isNotSupportedBoiler',
                                            error: cannotOfferError,
                                            page: 'BoilerDetails',
                                            value: values['make'],
                                          });
                                        } else {
                                          setExcludedModelSelected(
                                            BoilerBrandExclusions.NOTEXCLUDED
                                          );
                                        }
                                      }}
                                      defaultValue={excludedModelSelected}
                                      error={errors.isNotSupportedBoiler}
                                    >
                                      {boilerModelsExemptions.map((option) => (
                                        <Radio
                                          name="isNotSupportedBoiler"
                                          data-testid={`isNotSupportedBoiler-${option.name}`}
                                          id={`isNotSupportedBoiler-${option.name}`}
                                          key={option.label}
                                          label={option.label}
                                          value={option.name}
                                        />
                                      ))}
                                    </RadioField>
                                  </Margin>
                                </>
                              </InfoText>
                            </InfoContainer>
                          </StyledWarningNotification>
                        </Margin>
                      ) : null}
                      {!isAbs && (
                        <>
                          <Margin top={6} bottom={6}>
                            <Divider type={'differentiated'} />
                          </Margin>
                          <Heading3>How old is your boiler?</Heading3>
                          <Margin vertical={2} />
                          <FieldDescription>
                            Select from the drop-down below. If your boiler is actually older than
                            the age you’ve selected, it could invalidate your cover. So it’s worth
                            checking{' '}
                            <TextLink
                              target="_blank"
                              rel="noopener noreferrer"
                              href="https://www.ncm-pcdb.org.uk/sap/pcdbsearch.jsp?pid=26"
                              onClick={() => {
                                track(MixpanelEvents.BUTTON_CLICK, {
                                  buttonId: 'officialIndustryDB',
                                  page: 'BoilerDetails',
                                });
                              }}
                            >
                              the official industry database.
                            </TextLink>{' '}
                            if you’re not sure. You can also call us on{' '}
                            <TextLink
                              href="tel:0330 102 8905"
                              onClick={() => {
                                sessionStorage.setItem('lastElement', 'tel-link');
                                track(MixpanelEvents.BUTTON_CLICK, {
                                  buttonId: 'phoneNumber',
                                  page: 'BoilerDetails',
                                  value: '0330 102 8905',
                                });
                              }}
                            >
                              {' '}
                              0330 102 8905{' '}
                            </TextLink>{' '}
                            if you have any questions.
                          </FieldDescription>
                          {!errors.boilerAge &&
                            !errors.make &&
                            !isAbs &&
                            parseInt(values.boilerAge) > 6 && (
                              <>
                                <Margin vertical={6} />
                                {!selectedQuote?.offer && (
                                  <StyledInfoNotification
                                    nonce={undefined}
                                    id="boiler-age-threshold"
                                    title="We need to increase your price by £2 a month"
                                  >
                                    <P data-testid="premium-warning-no-promo">
                                      As your boiler is 7 years old or older, your monthly premium
                                      will increase by £{selectedQuote?.price.premium.monthly}.
                                    </P>
                                  </StyledInfoNotification>
                                )}
                                {selectedQuote?.offer &&
                                  getOlderBoilerPremiumNotification(selectedQuote)}
                              </>
                            )}
                          <Margin vertical={4} />
                          <SelectField
                            label="Select boiler's age"
                            name="boilerAge"
                            data-testid="boiler-age"
                            id="boilerAge"
                            options={[
                              emptySelection,
                              boilerAgeSelection,
                              ...(values.make ? getBoilerAgeOptionsBasedOnMake(values.make) : []),
                            ]}
                            value={values.boilerAge}
                            onChange={(e) => {
                              setBoilerDetails((boilerDetails) => ({
                                ...boilerDetails,
                                boilerAge: e.target.value,
                              }));
                              handleChange(e);
                            }}
                            error={
                              errors.boilerAge === boilerTooOldMessage
                                ? errors.boilerAge
                                : hasTriedToSubmit && errors.boilerAge
                            }
                            disabled={!values.make || values.make === BoilerBrand.OTHER}
                          ></SelectField>
                        </>
                      )}
                      <Margin top={6} bottom={6}>
                        <Divider type={'differentiated'} />
                      </Margin>
                      <Margin bottom={3}>
                        <Heading3>Before you move on, please confirm:</Heading3>
                      </Margin>
                      <Checkbox
                        name="ownership"
                        data-testid="ownership"
                        id="ownership"
                        label={
                          isHomeRecover
                            ? 'You’re the owner and live in the property, you only have one boiler and your boiler operates on mains natural gas.'
                            : 'You’re the owner and live in the property, you only have one boiler, your boiler operates on mains natural gas and is not currently in need of repair.'
                        }
                        checked={values.ownership}
                        onChange={handleChange}
                        error={hasTriedToSubmit && errors.ownership}
                      />
                      <Checkbox
                        name="notGuesthouse"
                        data-testid="not-guesthouse"
                        id="not-guesthouse"
                        label="Your property is not a guest house or bed and breakfast."
                        checked={values.notGuesthouse}
                        onChange={handleChange}
                        error={hasTriedToSubmit && errors.notGuesthouse}
                      />
                      {!isMobile && (
                        <>
                          <MarginDivider type={'differentiated'} />
                          <BasketNavigationButtonsV2
                            isLoading={isSubmitting}
                            nextText={nextText}
                            productCode={selectedProduct}
                            backButtonConfig={{
                              text: backButtonText,
                              onClick: handleBackButtonClick,
                            }}
                          />
                        </>
                      )}
                    </StyledCard>
                  </BasicPage.Left>
                  <BasicPage.Right>
                    <>
                      <BasketV2
                        showExcessToggle={!isAbs && journey !== Journey.InsuranceHighExcess}
                      />
                      {isMobile && (
                        <>
                          <Margin vertical={4} />
                          <BasketNavigationButtonsV2
                            isLoading={isSubmitting}
                            nextText={nextText}
                            productCode={selectedProduct}
                            backButtonConfig={{
                              text: backButtonText,
                              onClick: handleBackButtonClick,
                            }}
                          />
                        </>
                      )}
                    </>
                  </BasicPage.Right>
                </BasicPage.Content>
              </PageContentContainer>
            </Form>
          </div>
        );
      }}
    </Formik>
  );
};
