import { WindowLocation } from '@reach/router';
import { ErrorNotification, P } from '@ovotech/element';

import { NewBoilerDetailsInputs } from './NewBoilerDetailsInputs';

import { NewBasicPage, TopSummary } from '@components';
import { getStepTitle } from '@utils/getStepTitle';

export interface BoilerDetailsProps {
  location?: WindowLocation;
}

export const NewBoilerDetails = ({ location }: BoilerDetailsProps) => {
  const title = 'Boiler details';
  const aboveHeading = getStepTitle(location);

  if (!location) {
    return (
      <ErrorNotification id="boiler-details-error" nonce title={''}>
        <P>Sorry, we were unable to load your details. Please try again later.</P>
      </ErrorNotification>
    );
  }

  return (
    <NewBasicPage pageName="boilerDetails" location={location} warnOnLeaving>
      <TopSummary {...{ title, aboveHeading }} />

      <NewBoilerDetailsInputs />
    </NewBasicPage>
  );
};
