import styled from 'styled-components';
import { CTAButton } from '@ovotech/element';

import { LoadingSpinner } from '@components/LoadingSpinner/index';
import { AutoRenewalOption, MixpanelEvents } from '@entities/enums';
import { useMediaIs } from '@hooks/useMediaIs';
import { useCustomMixpanel } from '@services/Mixpanel';
import { mqMediumUp } from '@utils/mediaQuery';
import { PrimaryCTA } from '@components/PrimaryCTA';
import { ProductCode } from '@src/types/Quote';

interface Props {
  isLoading?: boolean;
  stacked?: boolean;
  nextText: string;
  productCode?: ProductCode;
  autoRenewal?: AutoRenewalOption;
  onClick?: () => void;
  backButtonConfig?: {
    text: string;
    onClick: () => void;
  };
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.core.space[4]};
  ${mqMediumUp(`flex-direction: row-reverse; justify-content: space-between;`)};
`;

export const BasketNavigationButtonsV2 = ({
  productCode,
  isLoading,
  onClick,
  nextText,
  autoRenewal,
  backButtonConfig,
}: Props) => {
  const { track } = useCustomMixpanel();
  const isMobile = useMediaIs('mobile', 'max');

  return (
    <Container>
      <PrimaryCTA
        data-testid="basket-cta"
        type="submit"
        {...(!isMobile && {
          iconRight: isLoading ? 'off' : 'chevron-right',
        })}
        variant="primary"
        disabled={isLoading}
        onClick={() => {
          if (onClick) {
            onClick();
          }

          track(MixpanelEvents.BUTTON_CLICK, {
            buttonId: 'nextPage',
            productCode,
            ...(autoRenewal && {
              autorenewalSelection: autoRenewal === AutoRenewalOption.YES ? 'opt-in' : 'opt-out',
            }),
          });
        }}
      >
        {isLoading ? <LoadingSpinner color="rgb(255, 255, 255)" /> : nextText}
      </PrimaryCTA>
      {backButtonConfig && (
        <CTAButton
          data-testid="basket-previous-cta"
          {...(!isMobile && { iconLeft: 'chevron-left' })}
          variant="secondary"
          type="button"
          disabled={isLoading}
          onClick={() => {
            track(MixpanelEvents.BUTTON_CLICK, {
              buttonId: 'backPage',
              productCode,
            });

            backButtonConfig?.onClick();
          }}
        >
          {backButtonConfig.text}
        </CTAButton>
      )}
    </Container>
  );
};
